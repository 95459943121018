.listItem {
    padding: 2px 16px 2px 23px !important;
    color: #fff;
}

.sidebarHeader {
    font-weight: bold !important;
    font-size: 1.06rem !important;
    color: rgba(255, 255, 255, 0.65) !important;
}

.sidebar-head {
    width: 255px;
}

.ellipsis {
    width: 200px !important;
    height: 22px;
    line-height: 22px;
    opacity: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis !important;
}

.right {
    float: right;
    color: #fff
}

.sidebar-nav-link {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}

.sidebar-nav-link:hover, .ellipsis:hover {
    color: white !important;
    opacity: 1;
}

.pointer {
    cursor: pointer;
}